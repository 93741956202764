<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
    v-model="tabIndex"
  >
    <b-tab>
      <template #title>
        <feather-icon icon="StarIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">{{ $t("field.review") }}</span>
      </template>

      <product-review v-if="tabIndex === 0" />
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTab, BTabs } from "bootstrap-vue";
import ProductReview from "./productReview/List.vue";

export default {
  components: {
    BTabs,
    BTab,

    ProductReview,
  },
  watch: {
    tabIndex(value) {
      this.$router.replace({
        query: {
          tabIndex: value,
        },
      });
    },
  },
  data() {
    return {
      tabIndex: this.$route.query.tabIndex
        ? Number(this.$route.query.tabIndex)
        : 0,
      options: {},
    };
  },
  mounted() {},
};
</script>
