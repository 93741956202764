<template>
  <div>
    <load-profile></load-profile>
    <!-- Table Container Card -->
    <b-card>
      <b-row>
        <b-col>
          <div></div>
          <div class="infinite-wrapper">
            <infinite-loading ref="infiniteLoading" @infinite="list">
              <div slot="spinner" class="mt-2">
                <b-spinner label="Spinning"></b-spinner>
              </div>
              <div slot="no-more"></div>
              <div slot="no-results"></div>
            </infinite-loading>
          </div>
        </b-col>
      </b-row>
      <div class="review-body">
        <b-row v-for="(review, idx) in reviews" :key="`review-${review.id}`">
          <b-col>
            <b-row align-v="center">
              <b-col cols="12" v-if="idx > 0">
                <hr class="invoice-spacing" />
              </b-col>
              <b-col cols="auto">
                <b-avatar
                  size="48"
                  class="avatar-border"
                  variant="light-primary"
                  :text="avatarText(review.outlet.ownerName)"
                  :src="review.outlet.image"
                >
                  <feather-icon
                    v-if="!review.outlet.ownerName"
                    icon="UserIcon"
                    size="32"
                  />
                </b-avatar>
              </b-col>

              <b-col class="pl-0">
                <h6 class="mb-0">{{ review.outlet.ownerName }}</h6>
                <small>
                  {{ trans(review.outlet, "name", $i18n.locale) }}
                  -
                  <span class="text-primary">
                    {{ review.outlet.outletCode }}
                  </span>
                </small>
              </b-col>
              <b-col cols="auto" align-self="start">
                {{ review.createdAt | formatShortDate }}
              </b-col>
              <b-col cols="12">
                <b-row align-v="center">
                  <b-col
                    class="quote"
                    :class="{
                      danger: !review.isEnable,
                    }"
                  >
                    <p class="mb-0">
                      <feather-icon
                        :class="{
                          'rate-star': i <= review.rating,
                          'unrate-star': i > review.rating,
                          danger: !review.isEnable,
                        }"
                        icon="StarIcon"
                        v-for="i in 5"
                        :key="`star-${i}`"
                      />
                    </p>
                    <p class="mb-0">
                      {{ review.comment }}
                    </p>
                  </b-col>
                  <b-col cols="auto">
                    <b-button
                      variant="danger"
                      size="sm"
                      v-if="review.isEnable"
                      @click="disableReview(review.id, idx)"
                    >
                      <feather-icon icon="XIcon" />
                    </b-button>
                    <b-button
                      variant="primary"
                      size="sm"
                      v-else
                      @click="enableReview(review.id, idx)"
                    >
                      <feather-icon icon="CheckIcon" />
                    </b-button>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
      <b-row>
        <b-col cols="12" class="text-right">
          <b-button
            type="button"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mt-1"
            @click="back"
          >
            {{ $t("button.back") }}
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { BCard, BRow, BCol, BButton, BSpinner, BAvatar } from "bootstrap-vue";
import Repository from "@/repositories/RepositoryFactory";
import Ripple from "vue-ripple-directive";
import InfiniteLoading from "vue-infinite-loading";
import { avatarText } from "@core/utils/filter";

const ProductReviewRepository = Repository.get("productReview");

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BSpinner,
    BAvatar,

    InfiniteLoading,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      reviews: [],
      total: 0,
      query: {
        page: 1,
        order: "id",
        sort: "DESC",
        productId: this.$route.params.id,
        searchFields: [
          {
            key: "productId",
          },
        ],
      },
      avatarText,
    };
  },
  methods: {
    list($state = null) {
      ProductReviewRepository.index(this.query)
        .then((response) => {
          this.query.page++;

          const data = response?.data?.data;
          if (data?.list) {
            this.reviews = [...data.list];
            this.total = data?.total;

            if ($state && this.reviews.length >= data.total) {
              $state.complete();
            }
          }
        })
        .catch()
        .then(() => {
          if ($state) {
            $state.loaded();
          }
        });
    },
    disableReview(id, index) {
      this.$bvModal
        .msgBoxConfirm(
          this.$t("alert.disableReview", {
            outletName:
              this.$i18n.locale == "en"
                ? this.reviews[index].outlet.nameEn
                : this.reviews[index].outlet.nameKm,
          }),
          {
            title: this.$t("alert.areYouSure"),
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: this.$t("button.disable"),
            cancelTitle: this.$t("button.cancel"),
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.changeStatus(id, index, false);
          }
        });
    },
    enableReview(id, index) {
      this.$bvModal
        .msgBoxConfirm(
          this.$t("alert.enableReview", {
            outletName:
              this.$i18n.locale == "en"
                ? this.reviews[index].outlet.nameEn
                : this.reviews[index].outlet.nameKm,
          }),
          {
            title: this.$t("alert.areYouSure"),
            size: "sm",
            buttonSize: "sm",
            okVariant: "primary",
            okTitle: this.$t("button.enable"),
            cancelTitle: this.$t("button.cancel"),
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.changeStatus(id, index, true);
          }
        });
    },
    changeStatus(id, index, isEnable) {
      ProductReviewRepository.updateStatus(id, isEnable)
        .then((response) => {
          this.reviews[index].isEnable = isEnable;
        })
        .catch()
        .then(() => {});
    },
    back() {
      this.$router.back();
    },
  },
  setup() {},
};
</script>